import { Radio, RadioGroup } from '@thanx/uikit/radio'
import { MultiSelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { withFormsy } from 'formsy-react'
import { buildTranslate } from 'locales'
import { HandoffMethod, HandoffMode } from 'models/RedeemTemplate'
import React, { useEffect, useState } from 'react'
import SelectedHandoffMode from './SelectedHandoffMode'

export type HandoffModeOption = {
  name: string
  value: HandoffMode
}

type Props = {
  disabled?: boolean
  editingDisabled?: boolean
  handoffModes: HandoffMode[] | null
  location?: 'campaignBuilder' | 'rewardTemplate'
  name: string
  setValue: (value: HandoffMode[] | null) => void
}

const t = buildTranslate('redeem_manager.builder.handoff_modes')

const HandoffModesSelect = ({
  disabled,
  handoffModes,
  location,
  setValue,
  editingDisabled,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)

  const [handoffMethod, setHandoffMethod] = useState(
    handoffModes?.length
      ? ('restricted' as HandoffMethod)
      : ('unrestricted' as HandoffMethod)
  )

  // Local handoff mode state for optimistic updates
  const [localHandoffModes, setLocalHandoffModes] = useState<
    HandoffMode[] | null
  >(handoffModes || [])

  // Effect to synchronize local state with form state
  useEffect(() => {
    setValue(localHandoffModes?.length ? localHandoffModes : null)
  }, [localHandoffModes, setValue])

  const options: HandoffModeOption[] = [
    { name: t('modes.pickup'), value: 'pickup' },
    { name: t('modes.delivery'), value: 'delivery' },
    { name: t('modes.curbside'), value: 'curbside' },
    { name: t('modes.tableside'), value: 'tableside' },
    { name: t('modes.drivethru'), value: 'drivethru' },
    { name: t('modes.dinein'), value: 'dinein' },
  ]

  const selectedHandoffModes = options.filter(
    handoffMode =>
      localHandoffModes && localHandoffModes.includes(handoffMode.value)
  )

  const deleteItem = (deletedHandoffModeName: string) => {
    if (disabled) return

    const handoffMode = options.find(
      option => option.name === deletedHandoffModeName
    )

    if (!handoffMode || !Array.isArray(localHandoffModes)) return

    const newValue = localHandoffModes.filter(
      handoffModeValue => handoffModeValue !== handoffMode.value
    )

    setLocalHandoffModes(newValue)
  }

  const enabledHandoffModes = handoffModes
    ?.map((handoffMode: string) => t(`modes.${handoffMode}`))
    .join(` ${buildTranslate('shared_words')('or')} `)

  const inCampaignBuilder = location === 'campaignBuilder'

  return (
    <div
      className={inCampaignBuilder ? '' : 'mt-4'}
      data-testid="handoff-modes-select"
    >
      <p
        className={`${
          inCampaignBuilder ? 'font-size-18 mb-m' : 'body-text-4'
        } bold`}
      >
        {inCampaignBuilder
          ? t('campaign_hand_off_method')
          : t('hand_off_method')}
      </p>

      <Switch condition={Boolean(editingDisabled && inCampaignBuilder)}>
        <p className="font-size-16 grey-70">
          {handoffModes?.length ? enabledHandoffModes : t('any_method')}
        </p>
        <p className="mt-xs body-text-4 grey-70">
          {t('configured_on_reward_template')}
        </p>
      </Switch>

      <Switch condition={!editingDisabled}>
        <p className="mt-xs body-text-4 grey-70">{t('can_redeem')}</p>
        <RadioGroup
          disabled={disabled}
          name="handoffMethod"
          value={handoffMethod}
          onChange={handoffMethod => {
            if (handoffMethod === 'unrestricted') {
              setLocalHandoffModes(null)
            }
            setHandoffMethod(handoffMethod)
          }}
        >
          <Radio
            disabled={disabled}
            className="w-100"
            overrides={{
              Label: {
                style: {
                  width: '100%',
                },
              },
              Root: {
                style: {
                  alignItems: 'flex-start',
                },
              },
            }}
            value={'unrestricted'}
          >
            <Text.BodyText3 tag="p" color="grey70" className="mb-xs">
              {t('any_method')}
            </Text.BodyText3>
          </Radio>
          <Radio disabled={disabled} value={'restricted'}>
            {t('specific_method')}
          </Radio>
          <Switch condition={handoffMethod === 'restricted'} fallback={<></>}>
            <div className={`w-100 pl-l`}>
              <MultiSelect
                disabled={disabled}
                placeholder={isFocused ? t('search') : t('select_a_method')}
                maxDropdownHeight="400px"
                isLoading={false}
                options={options}
                labelKey="name"
                valueKey="value"
                value={selectedHandoffModes}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onChange={selections => {
                  const handoffModeSelections =
                    selections as HandoffModeOption[]
                  setLocalHandoffModes(
                    handoffModeSelections.map(
                      handoffModeSelection => handoffModeSelection.value
                    )
                  )
                }}
              />
              <div className="mt-s">
                {selectedHandoffModes.map(handoffMode => (
                  <SelectedHandoffMode
                    key={handoffMode.name}
                    disabled={disabled}
                    name={handoffMode.name}
                    deleteItem={deleteItem}
                  />
                ))}
              </div>
            </div>
          </Switch>
        </RadioGroup>
      </Switch>
    </div>
  )
}

export default withFormsy(HandoffModesSelect)
