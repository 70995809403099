import {
  ADD_FIELDS_TO_CAMPAIGN,
  ARCHIVE_CAMPAIGN_SUCCESS,
  CANCEL_CAMPAIGN_SUCCESS,
  COMPLETE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_SUCCESS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_SUCCESS,
  ResolvedAction,
  SCHEDULE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_SUCCESS,
} from 'actions/campaigns'
import {
  CREATE_CAMPAIGN_VARIANT_SUCCESS,
  DELETE_CAMPAIGN_VARIANT_SUCCESS,
  GET_CAMPAIGN_VARIANTS_SUCCESS,
  ResolvedAction as VariantResolvedAction,
  UPDATE_CAMPAIGN_VARIANT_SUCCESS,
} from 'actions/campaignVariants'
import { RESET_DB } from 'actions/orm'
import moment from 'moment-timezone'
import { attr, fk, Model, ModelType } from 'redux-orm'

import { RedeemMetric } from 'models/CampaignMetric'
import { ImageUrlsT } from 'utilities/types'
import { Fields as Variant, VariantMeta } from './CampaignVariant'
import { getMeta, sortVariants } from './CampaignVariant/helpers'

import { Fields as MerchantUserFields } from './MerchantUser'
import {
  HandoffMode,
  RedeemTemplateSubtype,
  RedeemType,
} from './RedeemTemplate'

const minimizeVariant = (
  variant: Variant | MinimalVariant,
  index: number
): MinimalVariant => ({
  id: variant.id,
  campaign_id: variant.campaign_id,
  name: variant.name,
  created_at: variant.created_at,
  notify_email: variant.notify_email,
  notify_push: variant.notify_push,
  notify_sms: variant.notify_sms,
  internal_type: variant.internal_type,
  redeem_type: variant.redeem_type,
  meta: getMeta(variant.internal_type, variant.name, index),
})

/* eslint-disable no-use-before-define */ // @ts-ignore
export default class Campaign extends Model<typeof Campaign, Fields> {
  /* eslint-enable no-use-before-define */
  static modelName = 'Campaign'

  static get fields() {
    return {
      id: attr(),
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'campaigns',
      }),
      type: fk('CampaignType', 'campaigns'),
      redeem_template_id: fk({
        to: 'RedeemTemplate',
        as: 'redeemTemplate',
        relatedName: 'campaigns',
      }),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB } | VariantResolvedAction,
    // @ts-ignore
    Campaign: ModelType<Campaign>,
    session: any
  ) {
    /* eslint-enable no-use-before-define */
    let userTimeZone = moment.tz.guess()
    if (session.MerchantUser.first()) {
      userTimeZone = session.MerchantUser.first().time_zone
    }

    function updatedCampaign(campaign: Fields) {
      function formatDate(date: string) {
        return moment.tz(date, userTimeZone).format()
      }

      return {
        ...campaign,
        campaign_config_type: 'campaign' as configTypes,
        redeem_start_at: campaign.redeem_start_at
          ? formatDate(campaign.redeem_start_at)
          : campaign.redeem_start_at,
        redeem_end_at: campaign.redeem_end_at
          ? formatDate(campaign.redeem_end_at)
          : campaign.redeem_end_at,
        redeem_template_id:
          //@ts-ignore
          campaign.redeem_template_id ?? action.meta?.previousAction.templateId,
        variants: campaign.variants?.sort(sortVariants).map((v, i) => ({
          ...v,
          meta: getMeta(v.internal_type, v.name, i),
        })),
      }
    }

    switch (action.type) {
      case GET_CAMPAIGNS_SUCCESS:
        action.payload.data.campaigns.forEach(campaign => {
          campaign.stats?.forEach(metric => {
            session.CampaignMetric.upsert({
              ...metric,
              id: `${campaign.id}${metric.type}`,
            })
          })
          Campaign.upsert(updatedCampaign(campaign))
        })
        break
      case CREATE_CAMPAIGN_SUCCESS:
      case GET_CAMPAIGN_SUCCESS:
      case SCHEDULE_CAMPAIGN_SUCCESS:
      case UPDATE_CAMPAIGN_SUCCESS:
      case CANCEL_CAMPAIGN_SUCCESS:
      case COMPLETE_CAMPAIGN_SUCCESS:
      case ARCHIVE_CAMPAIGN_SUCCESS:
        Campaign.upsert(updatedCampaign(action.payload.data.campaign))
        break
      case ADD_FIELDS_TO_CAMPAIGN:
        Campaign.upsert(action.campaign)
        break
      case CREATE_CAMPAIGN_VARIANT_SUCCESS: {
        const { variant } = action.payload.data
        const campaign = Campaign.withId(variant.campaign_id)
        const variants = campaign?.variants || []
        campaign?.update({
          variants: [...variants, variant]
            .sort(sortVariants)
            .map((v, i) => minimizeVariant(v, i)),
        })
        break
      }
      case DELETE_CAMPAIGN_VARIANT_SUCCESS:
        const { campaignId, variantId } = action.meta.previousAction
        const campaign = Campaign.withId(campaignId)
        const variants = campaign?.variants || []
        const index = variants.findIndex(v => v.id === variantId)
        variants.splice(index, 1)
        campaign?.update({
          variants: variants
            .sort(sortVariants)
            .map((v, i) => minimizeVariant(v, i)),
        })
        break
      case UPDATE_CAMPAIGN_VARIANT_SUCCESS: {
        const { variant } = action.payload.data
        const campaign = Campaign.withId(variant?.campaign_id)
        const variants = campaign?.variants?.sort(sortVariants) || []
        const index = variants.findIndex(v => v.id === variant.id)
        variants.splice(index, 1, minimizeVariant(variant, index))

        campaign?.update({
          variants,
        })
        break
      }
      case GET_CAMPAIGN_VARIANTS_SUCCESS: {
        const { variants } = action.payload.data
        const campaign = Campaign.withId(variants[0]?.campaign_id)
        const minVariants = variants
          ?.sort(sortVariants)
          .map((variant, i) => minimizeVariant(variant, i))

        campaign?.update({
          variants: minVariants,
        })
        break
      }

      case RESET_DB:
        Campaign.all().delete()
        break
      default:
        break
    }
  }
}

export type RedeemVenueT = 'all' | 'instore' | 'online'
export type CampaignStateT =
  | 'draft'
  | 'in_review'
  | 'scheduled'
  | 'active'
  | 'complete'
  | 'archived'

export interface NotifyEmailConfig {
  notify_email_body: string
  notify_email_button_text: string
  notify_email_button_text_color: string
  notify_email_button_url: string
  notify_email_clickable: boolean
  notify_email_configuration: Record<string, any>
  notify_email_conversion: boolean
  notify_email_header_image_included: boolean
  notify_email_header_image_urls: ImageUrlsT
  notify_email_header_url: string
  notify_email_heading: string
  notify_email_hero_image_included: boolean
  notify_email_hero_image_urls: ImageUrlsT
  notify_email_hero_url: string
  notify_email_link_color: string
  notify_email_preview_text: string
  notify_email_redeem_box_color: string
  notify_email_redeem_box_text_color: string
  notify_email_redeem_button_color: string
  notify_email_redeem_button_text_color: string
  notify_email_subject: string
  notify_email_variant: string
}

export interface NotifyConfig extends NotifyEmailConfig {
  notify_email: boolean
  notify_push: boolean
  notify_push_text: string
  notify_sms: boolean
  notify_sms_text: string
}

export interface RedeemConfig {
  redeem: boolean
  redeem_description: string
  redeem_discount: number
  redeem_discounted_product_ids?: string[]
  redeem_fine_print: string
  redeem_maximum: number
  redeem_minimum: number
  redeem_required_product_ids?: string[]
  redeem_restriction: boolean
  redeem_restriction_description: string
  redeem_restriction_friday: Array<number>
  redeem_restriction_location_ids: Array<number>
  redeem_restriction_locations_description: string
  redeem_restriction_monday: Array<number>
  redeem_restriction_saturday: Array<number>
  redeem_restriction_sunday: Array<number>
  redeem_restriction_thursday: Array<number>
  redeem_restriction_time_range: boolean
  redeem_restriction_time_range_description: string
  redeem_restriction_time_zone: string
  redeem_restriction_tuesday: Array<number>
  redeem_restriction_wednesday: Array<number>
  redeem_experience_expires_in_days: number
  redeem_retail_value: number
  redeem_retire_after_days: number
  redeem_subtype: RedeemTemplateSubtype
  redeem_template_id: number | null
  redeem_type: RedeemType
  redeem_uses_coupon_codes: boolean
  redeem_venue: RedeemVenueT
  redeem_long_description: string
  redeem_handoff_modes: HandoffMode[] | null
}

export type configTypes = 'campaign' | 'variant'
export interface CampaignConfig extends NotifyConfig, RedeemConfig {
  campaign_config_type?: configTypes
  fine_print: string
}

export interface MinimalVariant {
  id: number
  campaign_id: number
  name: string
  internal_type?: string
  created_at: string
  notify_email?: boolean
  notify_push?: boolean
  notify_sms?: boolean
  redeem_type?: string
  meta?: VariantMeta
}

export interface Fields extends CampaignConfig {
  id: number
  type: string
  merchant_id: number

  approver: MerchantUserFields | null
  config_category: string
  config_closing_at: string
  config_control_percent: number
  config_include_recently_rewarded_users: boolean
  config_location_id: number
  config_location_ids: number[]
  config_location_category_ids: number[]
  config_reopening_at: string
  config_signup_required: boolean
  config_tag_key: string
  config_tag_values: string
  config_target_location_ids: Array<number>
  config_target_member: boolean
  config_target_subscriber: boolean
  config_target_name: string
  config_target_segment_id: number
  config_vip_count: number
  created_by_thanx: boolean
  creator: MerchantUserFields
  earn_description: string
  end_at: string | null
  name: string
  objective: string
  start_at: string
  state: CampaignStateT
  created_at: string
  updated_at: string
  // currently contains only RedeemStats type
  stats?: RedeemMetric[]
  review_reason:
    | 'coupon_codes'
    | 'statement_credit'
    | 'approval_required'
    | null
  variants: MinimalVariant[]
  redeem_end_at: string | null
  redeem_start_at: string | null
  winning_variant_id: number | null
  test_ended_at: string | null
  variant_name: never
  internal_type: never
  allow_non_reward_messages?: boolean
  retarget_frequency: number | null
}

export type ChannelT = 'email' | 'push' | 'sms'
