import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import HandoffModesSelect from 'scenes/RedeemManager/Builder/ConfigurationStep/OnlineRedemptionCard/HandoffModesSelect'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import DateRange from './DateRange'
import DayPart from './DayPart'
import Divider from './Divider'
import RedeemMaximumInput from './RedeemMaximumInput'
import RedeemMinimumInput from './RedeemMinimumInput'
import RedemptionVenue from './RedemptionVenue'
import RestrictionSection from './RestrictionSection'
import RewardLength from './RewardLength'

type Props = {
  onClose: () => void
  campaign: Campaign
  isSaving: boolean
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator'
)

const DiscountRewardRestrictions: React.FC<Props> = props => {
  const { onClose, campaign, isSaving } = props
  const { config } = useCampaignConfig()

  const {
    redeem_type,
    redeem_minimum,
    redeem_maximum,
    redeem_discount,
    redeem_handoff_modes,
    redeem_template_id,
  } = config

  const template = useSelector((state: any) =>
    selectRedeemTemplate(state, redeem_template_id)
  )

  const allRedemptionVenuesEnabled = Boolean(
    template && template.redemption_venue === 'all'
  )

  const showRedeemMaximum =
    redeem_type &&
    (redeem_type.includes('percent') || redeem_type === 'manual/item')
  const showNewRewardLength = [
    'message',
    'custom',
    'custom_automated',
  ].includes(campaign.type)

  return (
    <>
      <Switch
        condition={showNewRewardLength}
        fallback={<DateRange campaign={campaign} />}
      >
        <RewardLength
          campaign={campaign}
          className="mb-xl"
          t={buildTranslate(
            'thanx_campaigns.builder.steps.incentive.restriction_creator.general'
          )}
        />
      </Switch>
      <Divider />
      {/* Restrict offer to customers who join loyalty program */}
      <Switch condition={!!campaign.config_target_subscriber}>
        <RestrictionSection
          toggleName="config_signup_required"
          toggleValue={campaign.config_signup_required}
          title={t('signup_required.title')}
          subtitle={t('signup_required.body')}
          alwaysShowSubtitle={true}
        />
        <Divider />
      </Switch>
      <DayPart config={config} isSaving={isSaving} />
      <Divider />
      <Switch condition={showRedeemMaximum}>
        <RedeemMaximumInput value={redeem_maximum} />
        <Divider />
      </Switch>
      <RedeemMinimumInput
        value={redeem_minimum}
        redeemDiscount={redeem_discount}
        redeemType={redeem_type}
      />
      <Divider />
      <RedemptionVenue
        template={template}
        config={config}
        name="redeem_venue"
      />
      <Switch condition={config.redeem_venue !== 'instore'}>
        <div className={`border-1 grey-30-border border-radius-5 p-m pt-0`}>
          <HandoffModesSelect
            editingDisabled={!allRedemptionVenuesEnabled}
            handoffModes={redeem_handoff_modes}
            location="campaignBuilder"
            name="redeem_handoff_modes"
          />
        </div>
      </Switch>
      <div className="text-right margin-top-extra-large">
        <Button onClick={onClose}>{t('okay')}</Button>
      </div>
    </>
  )
}
export default DiscountRewardRestrictions
