import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { useHeaderContext } from 'components/Header'
import Formsy from 'formsy-react'
import { Fields as Campaign } from 'models/Campaign'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Confirmation from 'scenes/CampaignCenter/Builder/components/Confirmation'
import ContinueButton from 'scenes/CampaignCenter/Builder/components/ContinueButton'
import TopBar from 'scenes/CampaignCenter/Builder/components/TopBar'
import InfoModal from 'scenes/CampaignCenter/components/InfoModal'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import FormManagementContext from '../components/FormManagementContext'
import FormModelContext from '../components/FormModelContext'
import { useShowV3 } from '../hooks'
import { reviewDisabled } from './helper'

type StepType = {
  campaign: Campaign
  isSaving: boolean
  canProceed: boolean
  triggerSave: (campaignModel: any) => Promise<void>
  setCurrentStep: (step: string) => void
  showValidationErrors: () => void
}

type Props = {
  campaign: Campaign
  currentStep: React.ComponentType<StepType>
  currentStepName: string
  setCurrentStep: (step: string) => void
  isSaving: boolean
  canProceed: boolean
  triggerSave: (campaignModel: any) => Promise<void>
  excludeNav: boolean
  setValid: () => void
  setInvalid: () => void
}

const View: React.FC<Props> = props => {
  const {
    currentStepName,
    currentStep,
    canProceed,
    isSaving,
    campaign,
    setCurrentStep,
    excludeNav,
    setValid,
    setInvalid,
  } = props
  const [css] = useStyletron()
  const [formModel, setFormModel] = useState<any>({})
  const form = useRef<Formsy | null>(null)
  const showV3 = useShowV3(campaign?.type)
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const { shouldShow } = useHeaderContext()

  function isReview() {
    return currentStepName === 'review'
  }

  function handleClick() {
    if (!isReview()) {
      setCurrentStep('review')
    }
  }

  function showValidationErrors() {
    if (!form.current) return
    form.current.setFormPristine(false)
  }

  async function triggerSave() {
    if (!form.current) return
    props.triggerSave(form.current.getModel())
    //@ts-ignore
    return await props.triggerSave.flush()
  }

  function onFormChange(model: Record<string, any>) {
    // Only set handoff mode restrictions if online redemption is enabled
    if (model.redeem_venue === 'instore') {
      model.redeem_handoff_modes = null
    }

    setFormModel(model)
    if (isSaving) {
      setTimeout(() => props.triggerSave(model), 1000)
    } else {
      props.triggerSave(model)
    }
  }

  const Step = currentStep
  if (!Step) return null
  const backgroundSteps = ['review', 'name', 'variantSelect', 'setup']
  if (campaign.type !== 'message') {
    backgroundSteps.push('audience')
  }
  const newBackground =
    currentStepName === 'review' ||
    (showV3 && backgroundSteps.includes(currentStepName))
      ? 'grey-05-bkg'
      : ''

  const isEditStep = !shouldShow
  const marginTop = isEditStep ? '30px' : '80px'
  return (
    <div
      className={`${newBackground} ${css({
        marginTop,
        minHeight: `calc(100% - ${marginTop})`,
      })}`}
    >
      <FormManagementContext.Provider
        value={{
          isSaving: isSaving,
          canProceed: canProceed,
          triggerSave: triggerSave,
          setCurrentStep: setCurrentStep,
          showValidationErrors: showValidationErrors,
        }}
      >
        <FormModelContext.Provider value={formModel}>
          <Switch condition={!excludeNav}>
            <TopBar
              triggerSave={triggerSave}
              campaign={campaign}
              infoModal={
                <InfoModal
                  className="pull-left padding-top-micro margin-right-medium"
                  campaignType={campaign?.type}
                  campaignCategory={campaignType?.category}
                  withinBuilder
                >
                  <i
                    style={{ marginTop: '10px' }}
                    className="fa fa-info-circle fa-2x cursor-pointer"
                  />
                </InfoModal>
              }
              stepName={currentStepName}
              setCurrentStep={setCurrentStep}
            >
              <Switch
                condition={isReview()}
                fallback={<ContinueButton onClick={handleClick} />}
              >
                <Confirmation
                  campaign={campaign}
                  disabled={reviewDisabled(canProceed, isSaving)}
                  isSaving={isSaving}
                  triggerSave={triggerSave}
                  setCurrentStep={setCurrentStep}
                />
              </Switch>
            </TopBar>
          </Switch>
          <Formsy
            onChange={onFormChange}
            ref={form}
            onValid={setValid}
            onInvalid={setInvalid}
          >
            <Step
              campaign={campaign}
              isSaving={isSaving}
              canProceed={canProceed}
              triggerSave={triggerSave}
              setCurrentStep={setCurrentStep}
              showValidationErrors={showValidationErrors}
            />
          </Formsy>
        </FormModelContext.Provider>
      </FormManagementContext.Provider>
    </div>
  )
}

export default View
