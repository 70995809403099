import { ImagePickerValue } from 'components/ImagePicker'
import { CouponPoolGenerationType, CouponPoolType } from 'models/CouponPool'
import { RedeemProductIds } from 'models/RedeemTemplate'
import { HandoffMode } from 'models/RedeemTemplate'
import { ValidationTypes } from 'models/Validation'
import { createContext, useContext } from 'react'
import {
  InstoreRedemptionType,
  RedeemTypeItem,
} from 'scenes/RedeemManager/types'

export type FormModel = {
  typeItem: RedeemTypeItem | null
  name: string
  description: string
  discount: string | null
  perceivedValue: string
  availableOnline: boolean
  availableInstore: boolean
  discountedProductIds: string[]
  handoffModes: HandoffMode[] | null
  requiredProductIds: string[]
  productIds?: RedeemProductIds
  instoreDiscountedProductIds: string[]
  instoreRequiredProductIds: string[]
  onlineDiscountedProductIds: string[]
  onlineRequiredProductIds: string[]
  instoreRedemptionType: InstoreRedemptionType
  availableCustomerSupport: boolean
  hiddenMenuKey?: string[] | string
  hiddenMenuName?: string
  url?: string
  longDescription: string
  images: {
    [ValidationTypes.RedeemAdvertising]: ImagePickerValue | undefined
    [ValidationTypes.RedeemDetail]: ImagePickerValue | undefined
    [ValidationTypes.RedeemIndex]: ImagePickerValue | undefined
  }
  numberOfPoints?: number

  couponCodes: {
    generationType: CouponPoolGenerationType
    type: CouponPoolType
    staticCode: string
    uploadColumn: number | null
  }
  selectedCouponPools: any[]
}

export type ModelContext = {
  model: FormModel
  setModel: (f: FormModel) => void
}

const FormModelContext = createContext<ModelContext | null>(null)

export const FormModelProvider = FormModelContext.Provider

export function useFormModel(): ModelContext {
  const modelContext = useContext(FormModelContext)

  if (modelContext === null) {
    const errorInfo = 'useFormModel must be used within a FormModelProvider'
    throw errorInfo
  }

  return modelContext
}
