import { RESET_DB } from 'actions/orm'
import { GET_REDEEMS_SUCCESS, ResolvedAction } from 'actions/redeems'
import { HandoffMode } from 'models/RedeemTemplate'
import { fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  id: string
  merchant_id: number
  redeem_fine_print: string
  redeem_description: string
  redeem_type: string
  redeem_discount: number
  redeem_retail_value: number
  redeem_minimum: number
  redeem_maximum: number
  redeem_handoff_modes: HandoffMode[] | null
}

// @ts-ignore
export default class Redeem extends Model<typeof Redeem, Fields> {
  static modelName: string = 'Redeem'

  static get fields(): any {
    return {
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'redeems',
      }),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Redeem: ModelType<Redeem>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_REDEEMS_SUCCESS:
        action.payload.data.redeems.forEach(redeem => {
          Redeem.upsert({
            ...redeem,
            id: `${redeem.merchant_id}.${redeem.redeem_description}`,
          })
        })
        break
      case RESET_DB:
        Redeem.all().delete()
        break
      default:
        break
    }
  }
}
