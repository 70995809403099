import configuration from './configuration'
import detail from './detail'
import landing from './landing'

export default {
  helpers: {
    currency: '$%{value}',
    percent: '%{value}%',
    discounted: {
      default_label: 'Items that get discounted',
      free_items: 'Free items',
      they_for_free: 'They’ll be eligible to get another item for free',
      they_discount: 'They’ll be eligible to get a discount on another item',
    },
  },
  landing,
  detail,
  templates_status: {
    published: 'active',
    unpublished: 'draft',
    archived: 'archived',
  },
  preview_card: {
    reward: 'reward',
    where_to_see: 'Where customers see this reward',
    reward_symbol: 'Reward symbol',
  },
  product_select: {
    select_item: 'Select at least one item',
    search: 'Search',
  },
  actions: {
    edit: 'Edit',
    activate: {
      action: 'Activate',
      title: 'Activate this reward template',
      headline:
        'Activating this reward template allows you and your team to start using it in campaigns, loyalty programs, and customer feedback.',
      description:
        'Don’t worry, rewards won’t be sent to customers until you send a campaign or grant this reward when responding to customer feedback.',
      submit_button: 'Activate template',
      error: "Your reward template '%{name}' was unable to be activated.",
      success: "Your reward template '%{name}' has been activated.",
    },
    delete: {
      action: 'Delete',
      title: 'Delete this draft',
      description:
        'Are you sure you want to delete this draft? You won’t be able to undo this action.',
      submit_button: 'Delete draft',
      error: "The draft '%{name}' was unable to be deleted.",
      success: "The draft '%{name}' has been deleted.",
    },
    archive: {
      action: 'Archive',
      title: 'Archive this reward template',
      headline:
        'Archiving templates you no longer use hides them from the reward selection dropdown in campaigns and in customer feedback.',
      description:
        'Active campaigns and loyalty programs that currently use this template will continue to issue rewards. Customers who received a reward will still be able to redeem it.',
      submit_button: 'Archive template',
      error: "The reward template '%{name}' was unable to be archived.",
      success: "The reward template '%{name}' has been archived.",
    },
    cancel: 'Cancel',
  },
  send_a_test: {
    successful:
      'Your test reward was successfully sent. It may take a few minutes to receive it. Open your %{merchantName} app to redeem it.',
    modal: {
      title: 'Send a test to your %{merchantName} app',
      enter_emails:
        'Enter the email address you use to login to your <a href="https://signup.thanx.com/%{handle}/join" target="_blank">%{merchantName}</a> account.',
      it_may_take: '(It may take a few minutes to issue a test reward)',
      email_address: 'Email address',
      separate_emails:
        'Separate multiple email addresses with commas or spaces',
      cancel: 'Cancel',
      submit: 'Send test',
      info_box: {
        title: 'Testing online redemption',
        content_1:
          'When you receive your first test of this reward, the app or web experience will automatically redirect you to the hidden menu. This is how your customers will experience this reward.',
        content_2:
          'Subsequently viewing this reward, either by sending additional tests or by viewing on a different device, will not redirect you to the hidden menu because your hidden menu reward is already active on your account.',
      },
      successful:
        'Your test reward was successfully sent. It may take a few minutes to receive it. Open your %{merchantName} app to redeem it.',
    },
  },
  builder: {
    page_title: 'Edit reward template | Thanx',
    edit: {
      success: "The reward template '%{name}' has been updated.",
    },
    handoff_modes: {
      select_a_method: 'Select a hand-off method',
      hand_off_method: 'Hand-off method',
      campaign_hand_off_method: 'Which hand-off method the customer must use',
      can_redeem:
        'Customers can only redeem this reward if the order is set to the hand-off method below',
      configured_on_reward_template:
        'You configured this on the reward template',
      any_method: 'Any hand-off method',
      specific_method: 'A specific hand-off method',
      modes: {
        pickup: 'Pickup',
        delivery: 'Delivery',
        curbside: 'Curbside',
        tableside: 'Tableside',
        drivethru: 'Drivethru',
        dinein: 'Dine-in',
      },
    },
    leaving_page_confirmation: {
      title: 'Save this reward template?',
      description:
        'You are leaving this reward template. Would you like to save it before exiting?',
      save_exit: 'Save & exit',
      dont_save_exit: "Don't save & exit",
      invalid_form: 'Please fill all fields before saving the template.',
    },
    success_page: {
      all_done: "You're all done",
      template_is_active: 'Your reward template is active.',
      able_to_see:
        'You will now be able to see it when creating campaigns or responding to customer feedback.',
      go_to_rewards: 'Go to rewards',
      go_to_campaigns: 'Go to campaigns',
    },
    error:
      'Something went wrong while creating the template. Please try again.',
    buttons: {
      enable: 'Enable',
      disable: 'Disable',
      next: 'Next',
    },
    top_bar: {
      type_tab: 'type',
      name_tab: 'name',
      configuration_tab: 'configuration',
      back: 'Go back',
    },
    preview: {
      title: 'what customers see',
      summary: 'summary',
      reward_type: 'reward type',
      online_redemption: 'online redemption',
      instore_redemption: 'in-store redemption',
      customer_support: 'customer support',
      discount: 'discount',
      not_configured: 'You haven’t configured this yet',
      online_available: 'Can redeem online',
      online_not_available: 'Cannot redeem online',
      limited_to: 'Limited to %{count} items',
      limited_to_1: 'Limited to %{count} item',
      must_buy: 'Customer must buy any of %{count} qualifying items',
      must_buy_1: 'Customer must buy an item',
      instore_available: 'Can redeem in-store',
      instore_not_available: 'Cannot redeem in-store',
      support_available: 'Available for customer support',
      support_not_available: 'Not available for customer support',
      okay: 'Okay',
      where_to_see: 'Where customers see this reward',
      number_of_bonus_points: 'Number of bonus points',
      examples: {
        email:
          'When you send your campaign, customers will see the reward in your email, push, or SMS',
        appList:
          'When customers view the rewards page in the app, they’ll see available rewards',
        web: 'When customers are placing an order online, they’ll see available rewards in the reward dropdown',
        appDetail:
          'When customers click on the reward, they’ll be able to see the reward details',
        cart: 'When customers are placing an order online or in the app, they’ll see available rewards at the top of the cart',
      },
    },
    steps: {
      type: {
        title: 'Reward templates',
        non_discount: 'Non-Discount',
        discount: 'Free Items and Discounts',
        continue: 'Continue',
        need_different: 'Need to create a different type of reward? ',
        contact_us: 'Contact us',
        browse: 'Browse ideas',
        hidden_menu: {
          title: 'Hidden menu',
          subtitle: 'Give customers exclusive access to hidden menus',
        },
        golden_ticket: {
          title: 'Access pass',
          subtitle:
            'Offer custom digital and in-store experiences, gifts, and more',
          subtitle_disabled: 'Coming soon!',
        },
        bonus_points: {
          title: 'Bonus points',
          subtitle:
            'Give customers points to inspire engagement with your loyalty program',
          subtitle_disabled: 'Coming soon!',
        },
      },
      name: {
        title: 'Reward name',
        description:
          'This is how it will appear in your reward list when you create campaigns',
        visible_to_you:
          'This is only visible to you. It will not appear to your customers.',
        input_label: 'Name',
        continue: 'Continue',
        save_exit: 'Save & Exit',
      },
      configuration,
    },
    requires_olo: {
      title: 'Requires Thanx Ordering and Olo or Toast for online redemption.',
      learn_more: 'Learn more',
    },
  },
  types: {
    example: 'example',
    free_item: {
      title: 'free item',
    },
    discount: {
      title: 'discount',
    },
    buy_one_get_one: {
      title: 'buy one, get one',
    },
    buy_x_get_discount: {
      title: 'buy x, get discount off entire purchase',
    },
    get_a_free_item: {
      title: 'Get a free item',
      preview: 'Free item',
      example: 'Customer gets a free dessert',
      description_example: 'Get a free dessert',
    },
    'get_%_off_purchase': {
      title: 'Get % off entire purchase',
      preview: '%{discount}% off entire purchase',
      example: 'Customer gets 10% off their purchase',
      description_example: '20% off your purchase',
    },
    'get_%_off_item': {
      title: 'Get % off an item',
      preview: '%{discount}% off an item',
      example: 'Customer gets 20% off a burger',
      description_example: '20% off a burger',
    },
    get_$_off_purchase: {
      title: 'Get $ off entire purchase',
      preview: '$%{discount} off entire purchase',
      example: 'Customer gets $5 off their purchase',
      description_example: '$5 off their purchase',
    },
    get_$_off_item: {
      title: 'Get $ off an item',
      preview: '$%{discount} off an item',
      example: 'Customer gets $5 off a burger',
      description_example: '$5 off a burger',
    },
    'buy_x_get_%_off_purchase': {
      title: 'Buy item X, get % off entire purchase',
      preview: '%{discount}% off entire purchase',
      example: 'When a customer buys a burger, they get 10% off their purchase',
      description_example: 'Buy a burger, get 10% off your purchase',
    },
    'buy_item_get_%_off_item': {
      title: 'Buy one item, get % off another item',
      preview: 'Buy one item, get %{discount}% off another item',
      example:
        'Buy a burger, get 50% off your second burger<br/>OR<br/>Buy a burger, get 50% off fries.',
      description_example:
        'Get 50% off a burger when you buy another burger.<br/>OR<br/>Get 50% off fries when you buy a burger.',
    },
    buy_x_get_$_off_purchase: {
      title: 'Buy item X, get $ off entire purchase',
      preview: '$%{discount} off entire purchase',
      example: 'When a customer buys a burger, they get $5 off their purchase',
      description_example: 'Buy a burger, get $5 off your purchase',
    },
    buy_item_get_$_off_item: {
      title: 'Buy one item, get $ off another item',
      preview: 'Buy one item, get $%{discount} off another item',
      example:
        'Buy a burger, get $2 off your second burger<br/>OR<br/>Buy a burger, get $2 off fries.',
      description_example:
        'Get $2 off a burger when you buy another burger.<br/>OR<br/>Get $2 off fries when you buy a burger.',
    },
    buy_one_get_free_one: {
      title: 'Buy one item, get another item for free',
      preview: 'Buy one item, get another item for free',
      example:
        'Buy a burger, get a free burger<br/>OR<br/>Buy a burger, get free fries.',
      description_example:
        'Get a free burger when you buy another burger.<br/>OR<br/>Get free fries when you buy a burger.',
    },
    hidden_menu: {
      title: 'Hidden menu',
      preview: 'Hidden menu',
    },
    golden_ticket: {
      title: 'Access pass',
      preview: 'Access pass',
    },
    bonus_points: {
      title: 'Bonus points',
      preview: '%{points} bonus %{currencyName}',
    },
  },
}
