type Props = {
  disabled?: boolean
  deleteItem: (name: string) => void
  name: string
}

const SelectedHandoffMode = ({ disabled, deleteItem, name }: Props) => (
  <div
    className="d-flex align-items-center border-1 grey-30-border border-radius-5 grey-05-bkg p-xs mb-xs justify-content-between width-100 cursor-default"
    data-testid={`${name}-handoff-selected`}
  >
    <p className="mb-0 body-text-4 grey-70 pl-1 pr-1">{name}</p>
    <span
      tabIndex={0}
      className={`font-size-24 grey-70 light ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      title="delete"
      onClick={() => {
        if (disabled) {
          return
        }
        deleteItem(name)
      }}
    >
      &times;
    </span>
  </div>
)

export default SelectedHandoffMode
