export default {
  page_title: 'Reward template | Thanx',
  configuration: 'Configuration',
  usage_redemption: 'Usage and Redemption',
  where_its_used: "Where it's used",
  created_on: 'Created on %{date}',
  archived_on: 'Archived on %{date}',
  back: 'Back',
  send_a_test: 'Send a test',
  cards: {
    reward_details: {
      title: 'Reward details',
      reward_type: 'Reward type',
      discount: 'Discount amount',
      cost_to_business: 'Cost to business',
      retail_price: 'Retail price',
      how_it_appears: 'How it appears to customers',
      long_description: 'Description on the reward detail screen',
      how_many_bonus_points: 'How many bonus %{currencyName} customers get',
      will_apply_instantly:
        'This reward will apply instantly. Customers will not see it in the reward list.',
    },
    online_redemption: {
      hidden_menu: {
        title: 'Show this hidden menu to customers',
        name: 'Hidden Menu',
        info: 'This menu will be visible only to customers that get this reward',
        header: 'Menu header',
        custom_image: 'Show a custom image',
        congrats: 'Congratulations!',
        unlocked_menu: "You've unlocked a secret menu.",
        top_of_the_menu:
          'Customers see this banner at the top of the menu (app) or left of the menu (web). This banner is there to show customers that they are looking at a hidden menu.',
        show_default_message: 'Show the default message',
      },
      title: 'Online redemption',
      able_to_redeem: 'Customers are able to redeem this reward online',
      hand_off_method: 'Hand-off method',
      handoff_any:
        'Customers can redeem this reward if the order is set to any hand-off method',
      handoff_modes:
        'Customers can only redeem this reward if the order is set to %{handoffMode}',
      info: 'How the reward is redeemed online',
      items_discounted: 'Items that get discounted',
      items_not_available:
        'These items are no longer available from your ordering provider',
      items_required: 'Items required for the discount',
      not_redeemable: 'Not redeemable online',
      not_available:
        'Online redemption is only available when you have Thanx Ordering configured. ',
      contact_us: 'Contact us to learn more.',
      will_apply_lowest:
        'Discount will apply to the lowest priced item in cart',
      will_apply_lowest_free_item:
        'If the customer has more than one of the items above, the free item will be the lowest priced item in their cart.',
      then_entire_purchase:
        "Then they'll get a discount off their entire purchase",
      will_apply_instantly:
        'This reward will apply instantly the next time customers sign in to the app or web experience.',
    },
    instore_redemption: {
      title: 'In-store redemption',
      info: 'How the reward is redeemed in-store',
      redemption: 'Redemption',
      not_available: 'Not redeemable in-store',
      manager_comp: 'Manager comp',
      golden_ticket_info:
        'Customer can redeem in-store via the app by showing the screen to staff.',
      manager_comp_info:
        'Any restrictions must be enforced by staff or POS at checkout.',
      automatic_cash_back: 'Automatic cash back',
      redemption_image: {
        title: 'Redemption image',
        no_image: 'No image provided',
      },
      url: {
        title: 'Where this image links',
        no_url: 'This image does not include a link',
      },
      coupon_codes: {
        redemption: 'Redemption',
        enforced_by_pos: 'Enforced by POS',
        type: 'Type',
        integration: 'Integration between Thanx and the POS',
        entered_by_customer:
          'Customer enters their phone number or email at the POS.<br/>Then Thanx tells the POS which rewards are available to redeem for that customer.',
        issued_5_digit_code:
          'Each customer will be issued a unique 5 digit code at redemption.',
        pos_free_product_ids: 'POS Product IDs for free items in-store',
        pos_discounted_product_ids:
          'POS Product IDs for items that get discounted in-store',
        pos_required_product_ids:
          'POS Product IDs for items that the customer must buy in-store',
        format: 'Format',
        static_code: 'Static coupon code',
        pool_of_unique: 'Pool of unique codes',
        coupon_pool_details: 'coupon pool details',
        coupon_code: 'Coupon code',
        total_codes: 'Total codes',
        redeemed_codes: 'Redeemed codes (not available)',
        not_available_to_use:
          'These codes were applied to a reward that was redeemed by a customer',
        available_codes: 'Available codes',
        available_to_use:
          'These are available to use in new campaigns or to grant for customer support',
      },
      will_apply_instantly:
        'This reward will apply instantly the next time customers sign in to the app or web experience.',
    },
    customer_support: {
      title: 'Customer support',
      available:
        'Available to grant to an individual customer when responding to customer feedback or providing customer support.',
      not_available:
        'Not available to grant to an individual customer when responding to customer feedback or providing customer support.',
    },
  },
  usage: {
    title: 'Where this reward template is used',
    no_campaigns:
      'This reward template is not used in any campaigns or programs',
    create_a_campaign: 'Create a new campaign',
    rewards_issued: 'Rewards issued',
    rewards_redeemed: 'Rewards redeemed',
    redemption_rate: 'Redemption rate',
    table: {
      name: 'Name',
      type: 'Type',
      rewards_issued: 'Rewards\nissued',
      rewards_redeemed: 'Rewards\nredeemed',
      redemption_rate: 'Redemption\nrate',
      campaign_status: 'Campaign or\nprogram status',
    },
  },
}
